<template>
  <div class="login-module">
    <Login></Login>
  </div>
</template>

<script>
import Login from "../login/login.vue";
export default {
  data() {
    return {
    }
  },
  computed: {},

  components: {
    Login,
  },

  methods: {
  },

  destroyed() {
  },

  mounted() {
  },

  created() {
  },
}
</script>

<style lang="scss" scoped>
.login-module{
  width: 480px;
  min-height: 550px;
  background: rgba(255,255,255,1);
  border-radius: 8px;
  box-shadow:  1px 1px 21px 0 rgba(183,189,191,0.42);
  padding: 56px 46px 30px 46px;
  box-sizing: border-box;
  margin-top: 60px;
}
</style>