<template>
  <div class="LoginPage">
    <div class="middleBox">
      <div class="infoBox">
        <div class="imgBox">
          <img :src="web_all.logo1_url" alt="" class="login_logo" @click="toNavEvent" />
          <img src="@/assets/login_img.png" alt="" class="login_img" />
        </div>
        <div class="loginBox">
          <IndexLogin></IndexLogin>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IndexLogin from "@/components/indexLogin/indexLogin.vue";
export default {
  components: {
    IndexLogin,
  },
  data() {
    return {
      web_all: {},
    };
  },
  created() {
    this.getPower();
  },
  methods: {
    // 导航切换
    toNavEvent() {
      this.$router.push({
        path: "/",
      });
    },
    async getPower() {
      let res = await this.$axios.get("webConfig/getWebConfig");
      this.web_all = res.data.data;
    },
  },
};
</script>
<style lang="scss" scoped>
.LoginPage {
  min-width: 100%;
  min-height: 100vh;
  background-image: url("~@/assets/login_bj.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.middleBox {
  width: 1350px;
  height: 726px;
  box-sizing: border-box;
  position: relative;
  .infoBox {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    background-repeat: no-repeat;
    // background-image: url("~@/assets/bj.png");
    // background-size: 100% 100%;
    .imgBox {
      .login_logo {
        margin: -42px 0 0 20px;
        display: block;
        cursor: pointer;
      }
      .login_img {
        width: 840px;
        height: 620px;
        margin: 42px 0 0 -20px;
      }
    }
  }
  .loginBox {
    
  }
}
</style>
